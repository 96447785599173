import { Component, OnInit } from '@angular/core';

declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-apiintegration',
  templateUrl: './apiintegration.component.html',
  styleUrls: ['./apiintegration.component.css']
})
export class ApiintegrationComponent implements OnInit {

  constructor() { }

  setHeights(){
    var bannerbgapi = document.getElementById('bannerbgapi').clientHeight;
    document.getElementById("bannercontentapi").style.height = bannerbgapi+"px";
  }

  ngOnInit(): void {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }

}
