import { Component, OnInit } from '@angular/core';

declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-sme',
  templateUrl: './sme.component.html',
  styleUrls: ['./sme.component.css']
})

export class SmeComponent implements OnInit {

  constructor() { }

  setHeights(){
    var bannerbgsme = document.getElementById('bannerbgsme').clientHeight;
    document.getElementById("bannercontentsme").style.height = bannerbgsme+"px";
  }

  ngOnInit(): void {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }

}
