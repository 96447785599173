import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'Access-Control-Allow-Origin': 'http://localhost:4200/',
  })
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  contact_response = false;
  RequestDemoForm: FormGroup;

  constructor(private http:HttpClient, private frmBuilder:FormBuilder) {
    this.RequestDemoForm = frmBuilder.group({
      username: ['', Validators.required],
      useremail: ['', [Validators.required,Validators.email]],
      usermob: ['', [Validators.required,]],
      usercomp: ['', [Validators.required,]],
      userwebsite: ['', []],
      userabtstart: ['', [Validators.required,]],
      usermsg: ['', [Validators.required,]],
    });
  }

  reset(){
    this.RequestDemoForm.setValue({
      'username' : '',
      'useremail' : '',
      'usermob' : '',
      'usercomp' : '',
      'userwebsite' : '',
      'userabtstart' : '',
      'usermsg' : '',
    });
  }

  submitForm(RequestDemoForm){
    if (this.RequestDemoForm.valid) {
      const body=(RequestDemoForm.value);
      this.http.post('/apis/requestdemo', body ,httpOptions).subscribe(
        (response)=>{
          // console.log(response);
          this.contact_response=true;
          this.reset();
          setTimeout(() => {
            this.contact_response=false;
          }, 5000);
        },
        (err) => {
          // console.log(err);
        }
      );
    }
  }

  ngOnInit(): void {
  }

}