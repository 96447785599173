<main id="ts-content" style="margin-top: 100px;">

    <!--HERO CONTENT ****************************************************************************************-->
    <div class="container align-self-center">
        <div class="row align-items-center" id="bannercontentsme">
            <div class="col-sm-7">
                <h1>
                    Take your business online with our starter bundle
                </h1>
                <p style="color: white;font-size: 18px;">Grow your online presence with ewayorbit, the data-crunching, smart tech that offers advice tailored to your business.
                    <br><br>
                    <strong>At Just</strong><br><br>
                    <strong style="font-size: 50px;">Rs. 199/mo<sup>*</sup></strong><br><br>
                    <strong><sup>*</sup> Annual purchase required</strong><br>
                </p>
            </div>
            <!--end col-sm-7 col-md-7-->
            <div class="col-sm-5 d-none d-sm-block">
                <div class="owl-carousesl text-center" data-owl-nav="1" data-owl-loop="1">
                </div>
            </div>
            <!--end col-sm-5 col-md-5 col-xl-5-->
        </div>
        <!--end row-->
        <div class="ts-background" id="bannerbgsme" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3" style="height: 75%;">
            <div class="ts-background-image ts-parallax-element" data-bg-image="assets/img/banner-sme.jpg" data-bg-blend-mode="overlay"></div>
        </div>
    </div>
</main>
<!--end container-->
<!--WHAT IS APPSTORM ************************************************************************************-->
<section class="ts-block" data-bg-size="inherit" data-bg-position="left" data-bg-repeat="no-repeat">
    <div class="container">
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-5 col-xl-5" data-animate="ts-fadeInUp" data-offset="100">
                <div class="">
                    <h3 style="font-size: 2rem;">Make Your Ideas Come to Life with our started bundle</h3>
                </div>
                <p class="justify">
                    Whether you sell online, on social media, in store, or out of the trunk of your car, ewayorbit has you covered.
                    <br><br> Simply choose a theme, customize your site, add products and begin processing customers’ credit cards right away.
                    <br><br> No design skills needed. You have complete control over the content and colors.
                </p>
            </div>
            <div class="col-md-1 col-xl-1">&nbsp;</div>
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-delay="0.1s">
                <div class="xts-svg zts-svg__organic-shape-02 ts-background-size-contain  hover14" style="margin-top: 0;">
                    <figure class="sme shiny" style="background-image: url('assets/img/sme-d1.jpg');">
                        <img src="assets\img\sme-d1.jpg" class="mw-100 ">
                    </figure>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="ts-block ts-shape-mask__up xpb-6 xpt-6" data-bg-color="#ededed" data-bg-image="assets/img/bg-shapes.png" data-bg-size="contain" data-bg-repeat="no-repeat">
    <div class="">
        <div class="ts-title text-center">
            <h2>Available Designs</h2>
        </div>
        <!--end ts-title-->
        <div class="owl-carousel ts-carousel-centered xpy-2 xmb-5" data-owl-loop="1" data-owl-nav="1" data-owl-items="3" data-owl-margin="30" data-owl-center="1">
            <div class="slide">
                <div class="card hover14 " style="border-radius: 15px;">
                    <figure class="sme" style="background-image: url('assets/img/sme-d1.jpg');">
                        <img class="card-img-top" src="assets\img\sme-d1.jpg" alt="Card image" style="width:100%">
                    </figure>

                    <div class="card-body text-center">
                        <a href="http://ewayorbit.com/goexplore" target="_blank" class="btn btn-primary">View demo</a>
                    </div>
                </div>
            </div>
            <div class="slide">
                <div class="card hover14" style="border-radius: 15px;">
                    <figure class="sme" style="background-image: url('assets/img/sme-d2.jpg');">
                        <img class="card-img-top" src="assets\img\sme-d2.jpg" alt="Card image" style="width:100%">
                    </figure>

                    <div class="card-body text-center">
                        <a href="http://ewayorbit.com/mark-s1" target="_blank" class="btn btn-primary">View demo</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--end #gallery.ts-block-->

<section class="ts-block ts-shape-mask__ups xpb-6 xpt-6">
    <div class="container">
        <div class="ts-title text-center">
            <h2>What we offer at Rs. 199</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-lg-6 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part box_300">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\sme\dynamic_website.png" alt="" style="width: 80px;height: auto;">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Dynamic website</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        Select from any of our industry-leading website templates, designer fonts, and color palettes that best fit your personal style and professional needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part box_300">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\sme\admin_panel_sm.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Admin pannel</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        From controlling your supply chain processes to shipment tracking, integrations help you grasp every working process easily.<br>&nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part box_300">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\sme\247_sm.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>24x7 support</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        User friendly interfaces
                                        <br>&nbsp;<br>&nbsp;<br>&nbsp;<br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 md-mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part box_300">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\sme\social_media_sm.png" alt="" style="width: 85px;height: auto;">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Social links</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        Stand out in every inbox and social feed. On-brand email campaigns and social tools make it easy to retain customers and grow your audience.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<!--PRICING *********************************************************************************************-->
<section id="pricing" class="ts-block" data-bg-color="#ededed">
    <div class="container">
        <div class="ts-title text-center">
            <h2>Plans & Pricing</h2>
        </div>
        <!--end ts-title-->
        <div class="row no-gutters ts-cards-same-height">
            <!--Price Box Promoted-->
            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box ts-price-box__promoted" data-animate="ts-fadeInUp" data-delay="0.1s">
                    <div class="card-header p-0" data-bg-color="#d96270">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#f26d7d">Starter Pack</h5>
                        <div class="ts-title text-white py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">
                                Rs. 199
                            </h3>
                            <small class="ts-opacity__50">per month</small>
                        </div>
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>5 Pages Website</li>
                            <li>SSL</li>
                            <li>Custom Domain Connection</li>
                            <li>Admin Pannel</li>
                            <li>24/7 Support</li>
                            <li>Google Analytics</li>
                            <li>Social Media Platforms</li>
                            <li>Email Marketing</li>
                            <li><s class="ts-opacity__50">SEO</s></li>
                            <li><s class="ts-opacity__50">Live Chat</s></li>
                            <li><s class="ts-opacity__50">Payment Gateway</s></li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end price-box col-md-4-->

            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box" data-animate="ts-fadeInUp">
                    <div class="card-header p-0">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#1b1464">Standard</h5>
                        <div class="ts-title py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">Rs. 299</h3>
                            <small class="ts-opacity__50">per month</small>
                        </div>
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>5 Pages Website</li>
                            <li>SSL</li>
                            <li>Custom Domain Connection</li>
                            <li>Admin Pannel</li>
                            <li>24/7 Support</li>
                            <li>Google Analytics</li>
                            <li>Social Media Platforms</li>
                            <li>Email Marketing</li>
                            <li>SEO</li>
                            <li>Live Chat</li>
                            <li><s class="ts-opacity__50">Payment Gateway</s></li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-outline-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>

            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box" data-animate="ts-fadeInUp">
                    <div class="card-header p-0">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#1b1464">Premium</h5>
                        <div class="ts-title py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">Rs. 499</h3>
                            <small class="ts-opacity__50">per month</small>
                        </div>
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>5 Pages Website</li>
                            <li>SSL</li>
                            <li>Custom Domain Connection</li>
                            <li>Admin Pannel</li>
                            <li>24/7 Support</li>
                            <li>Google Analytics</li>
                            <li>Social Media Platforms</li>
                            <li>Email Marketing</li>
                            <li>SEO</li>
                            <li>Live Chat</li>
                            <li>Payment Gateway</li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-outline-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--END PRICING *****************************************************************************************-->

<!--How process works ********************************************************************************************-->
<section id="our-team" class="ts-block">
    <div class="container">
        <div class="ts-title">
            <h2>How process works</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="card text-center process_box" data-animate="ts-fadeInUp">

                    <div class="card-body">
                        <img src="assets\img\sme\Step1.png" width="100">
                        <br>
                        <br>
                        <h5 class="mb-1">Select Design</h5>
                    </div>

                </div>
                <!--end card-->
            </div>
            <!--end col-md-3-->
            <div class="col-sm-6 col-lg-3">
                <div class="card text-center process_box" data-animate="ts-fadeInUp">

                    <div class="card-body">
                        <img src="assets\img\sme\Step2.png" width="100">
                        <br>
                        <br>
                        <h5 class="mb-1">Provide details</h5>
                    </div>

                </div>
                <!--end card-->
            </div>
            <!--end col-md-3-->
            <div class="col-sm-6 col-lg-3">
                <div class="card text-center process_box" data-animate="ts-fadeInUp">

                    <div class="card-body">
                        <img src="assets\img\sme\Step3.png" width="100">
                        <br>
                        <br>
                        <h5 class="mb-1">Complete your purchase</h5>
                    </div>

                </div>
                <!--end card-->
            </div>
            <!--end col-md-3-->
            <div class="col-sm-6 col-lg-3">
                <div class="card text-center process_box" data-animate="ts-fadeInUp">

                    <div class="card-body">
                        <img src="assets\img\sme\Step4.png" width="100">
                        <br>
                        <br>
                        <h5 class="mb-1">Publish and Go live</h5>
                    </div>

                </div>
                <!--end card-->
            </div>
            <!--end col-md-3-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--END How process works ****************************************************************************************-->


<section id="our-clients" class="ts-block text-center" data-bg-color="#ededed">
    <div class="container">
        <div class="ts-title">
            <h2>Our Clients</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="owl-carousel ts-carousel-blockquote" data-owl-dots="1" data-animate="ts-zoomInShort">
                    <blockquote class="blockquote">
                        <!--person image-->
                        <figure>
                            <aside>
                                <i class="fa fa-quote-right"></i>
                            </aside>
                            <div class="ts-circle__lg" data-bg-image="assets/img/client/c22145623.jpg"></div>
                        </figure>
                        <!--end person image-->
                        <!--cite-->
                        <p>
                            The team has impressed us the most with awesome after sales service. They have built a very powerful flexible booking engine, which help us promoting our products.
                        </p>
                        <!--end cite-->
                        <!--person name-->
                        <footer class="blockquote-footer">
                            <h4>Rajeev Bhatia</h4>
                        </footer>
                        <!--end person name-->
                    </blockquote>
                    <!--end blockquote-->
                    <blockquote class="blockquote">
                        <!--person image-->
                        <figure>
                            <aside>
                                <i class="fa fa-quote-right"></i>
                            </aside>
                            <div class="ts-circle__lg" data-bg-image="assets/img/client/c22515135.jpg"></div>
                        </figure>
                        <!--end person image-->
                        <!--cite-->
                        <p>
                            Got our website created by Ewayorbit after searching a lot of OTA development companies in India . Thank you team for your cooperation ,the website was customized as we expected .
                        </p>
                        <!--end cite-->
                        <!--person name-->
                        <footer class="blockquote-footer">
                            <h4>Ervin Bell</h4>
                        </footer>
                        <!--end person name-->
                    </blockquote>
                    <!--end blockquote-->
                </div>
            </div>
        </div>

    </div>
</section>

<!--FAQS ********************************************************************************************-->
<section id="faqs" class="ts-block">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-xl-12">
                <div class="ts-title">
                    <button class="btn btn-primary disabled" disabled>FAQs</button>
                    <br>
                    <br>
                    <h3>Do You Have Any Questions?</h3>
                </div>
            </div>

            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100">
                <!--features list-->
                <ul class="list-unstyled ts-list-divided">
                    <li>
                        <a class="ts-font-color__black">
                            <h4 class="my-2">Why should I have a website?</h4>
                        </a>
                        <div id="f-1">
                            <p style="color: rgb(51 51 51);">
                                Having a website and online presence strategy allows you to market your business online. A website is also important because it helps to establish credibility as a business.A website not only gives credibility but it also helps to give a positive impression
                                that your company is bigger and more successful.
                            </p>
                        </div>
                        <!--end collapse-->
                    </li>
                    <li>
                        <a class="ts-font-color__black">
                            <h4 class="my-2">How can I keep my website secure ?</h4>
                        </a>
                        <div id="f-2">
                            <p style="color: rgb(51 51 51);">
                                Users expect a secure and private online experience when using a website.SSL protects website from phishing scams, data breaches, and many other threats. Ultimately, It builds a secure environment for both visitors and site owners. we provide an SSL certificate
                                in all plans in order to keep user data secure, verify ownership of the website, prevent attackers from creating a fake version of the site, and gain user trust.
                            </p>
                        </div>
                        <!--end collapse-->
                    </li>
                    <li>
                        <a class="ts-font-color__black">
                            <h4 class="my-2">Can I customize my website?</h4>
                        </a>
                        <div id="f-3">
                            <p style="color: rgb(51 51 51);">
                                Absolutely. You have the option to customize your website based on your business needs. You can add site content wherever you’d like in a variety of sections; from multiple site pages, to scrollable sections that you can add to each of those pages. We
                                provide and admin pannel with website, which allows customization in each section of the website. you can change from website logo to your website colour. you will get options to edit banners, to all images in website.
                                all the designs are fully mobile & tablet responsive to give your visitors the experience you want them to have.
                            </p>
                        </div>
                        <!--end collapse-->
                    </li>
                </ul>
                <!--end fs list-->
            </div>

            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100">
                <!--fs list-->
                <ul class="list-unstyled ts-list-divided">
                    <li>
                        <a class="ts-font-color__black">
                            <h4 class="my-2">Can i sell my products online with the help of starter bundle plan?</h4>
                        </a>
                        <div id="f-4">
                            <p style="color: rgb(51 51 51);">
                                Yes, you can sell your products online by opting for e-commerce plan. the website under this plan is designed so that you can add and edit product listings, complete with images, prices and descriptions, to your site. You will have options to categorize
                                your products which will help your clients to easily surf for their desired product.
                            </p>
                        </div>
                        <!--end collapse-->
                    </li>
                    <li>
                        <a class="ts-font-color__black">
                            <h4 class="my-2">What are the benefits of the Online Starter Bundle for my business?</h4>
                        </a>
                        <div id="f-5">

                            <ul style="color: rgb(51 51 51);">
                                <li>Low budget web development.</li>
                                <li>Wider audience reach due to website accessibility 24/7.</li>
                                <li>Brand visibility and brand recognition.</li>
                                <li>Easy online promotion of your business with a website.</li>
                                <li>Customer insights by tracking user behaviour on your website.</li>
                                <li>Sell products online</li>
                            </ul>

                        </div>
                        <!--end collapse-->
                    </li>

                </ul>
                <!--end fs list-->
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--FAQS ********************************************************************************************-->

<app-enquiryform></app-enquiryform>