import { Component, OnInit } from '@angular/core';

declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  setHeights(){
    var bannerbghome = document.getElementById('bannerbghome').clientHeight;
    document.getElementById("bannercontenthome").style.height = bannerbghome+"px";
  }

  ngOnInit() {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }
}
