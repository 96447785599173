<section id="bank_details" class="section-main clearfix">

    <div class="col-md-6 table-bordered" style="padding: 5.75% 35px;">
      <img src="images/Kotak_Bank_logo.png" alt="Kotak Mahindra Bank" style="width: 100%;">
    </div>
    <div class="col-md-6 table-bordered" style="padding: 3.7% 35px;">
      <table class="table table-bordered tableresponsive" style="margin: 0;">
        <tbody><tr>
          <td style="font-weight: bold;">Account Holder</td>
          <td>Expeditious Technologies Pvt. Ltd.</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">Account Number</td>
          <td>7413369342</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">IFSC Code</td>
          <td>KKBK0000150</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">Account Type</td>
          <td>Current</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">Branch</td>
          <td>Dhanbad</td>
        </tr>
      </tbody></table>
    </div>
</section>