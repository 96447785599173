<main id="ts-content" style="margin-top: 100px;">

    <!--HERO CONTENT ****************************************************************************************-->
    <div class="container align-self-center">
        <div class="row align-items-center" id="bannercontentapi">
            <div class="col-sm-7">
                <h1>
                    Third Party API
                    <br> Integrations
                </h1>
                <p style="color: white;font-size: 19px;">Ewayorbit, a leading travel portal develpment company, specializes in integrating XML and JSON based third party API integration for global travel companies.</p>

            </div>
            <!--end col-sm-7 col-md-7-->
            <div class="col-sm-5 d-none d-sm-block">
                <div class="owl-carousesl text-center" data-owl-nav="1" data-owl-loop="1">
                    <br><br><br>
                    <img src="assets\img\banner-api.png" class="d-inline-block mw-100 p-md-5 p-lg-0 xts-width__auto" alt="">
                    <br>&nbsp;<br>
                </div>
            </div>
            <!--end col-sm-5 col-md-5 col-xl-5-->
        </div>
        <!--end row-->
        <div class="ts-background" id="bannerbgapi" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3" style="height: 75%;">
            <div class="ts-background-image ts-parallax-element" data-bg-color="#4345d2" data-bg-image="assets/img/bg-pattern.jpg" data-bg-blend-mode="overlay"></div>
        </div>
    </div>

</main>
<section class="ts-block" data-bg-size="inherit" data-bg-position="left" data-bg-repeat="no-repeat">
    <div class="container">
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-6 col-xl-6">
                <div class="ts-svg ts-svg__organic-shape-02 ts-background-size-contain" style="margin-top: 0;">
                    <img src="assets\img\api-para2.png" class="mw-100 ts-border-radius__md" alt="">
                </div>
            </div>

            <div class="col-md-1 col-xl-1">&nbsp;</div>
            <div class="col-md-5 col-xl-5">
                <div class="">
                    <h3 style="font-size: 1.3rem;">API Integrations</h3>
                </div>
                <p class="justify">
                    Whether you need to sell tickets or merchandise, create a single sign-on (SSO) application, or display dynamic database-driven content, you don't have to develop solutions from scratch. Simply integrate software with ready-made solutions!
                    <br><br> Enterprise infrastructure nowadays realized through application integration, which are critical in it. Many different ideologies and approaches help to achieve those goals. When you just analyze data integration and application
                    solutions, it’s very easy to receive inappropriate information and get lost in different marketing terms, and opinions. But we are the company you’re looking for to assist you.
                    <br><br> We specialize in software integrations and provide solutions to accomplish your goals.
                </p>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--END WHAT IS APPSTORM py-2 mb-5********************************************************************************-->


<section class="ts-block ts-shape-mask__up xpb-6 xpt-6" data-bg-color="#ededed" data-bg-image="assets/img/bg-shapes.png" data-bg-size="contain" data-bg-repeat="no-repeat">
    <div class="container">
        <div class="ts-title text-center">
            <h2>Why Choose Us</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-lg-4 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\1.png" alt="" style="width: 80px;height: auto;">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Improved employee productivity</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        Ease the daily working processes with the tools that replace the routines. Help your employees focus on more strategic and creative tasks instead of manual work.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\2.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Real-time visibility<br>&nbsp;</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        From controlling your supply chain processes to shipment tracking, integrations help you grasp every working process easily.
                                        <br><br><br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\3.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>UI/UX <br>&nbsp; </a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        User friendly interfaces
                                        <br><br><br><br><br>&nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 md-mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\4.png" alt="" style="width: 85px;height: auto;">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Lower IT and operational costs</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        Since you don't have to build software from scratch, you can efficiently cut down costs on development and start getting first results quickly.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 sm-mb-5">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\5.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>Dedicated Team<br>&nbsp;</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        We have a dedicated team of professionals having many years of combined experience helping online travel agency grow.
                                        <br>&nbsp;<br>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="flip-box-inner">
                    <div class="flip-box-wrap">
                        <div class="front-part">
                            <div class="front-content-part">
                                <div class="front-icon-part">
                                    <div class="icon-part">
                                        <img src="assets\img\webicons\api\6.png" alt="">
                                    </div>
                                </div>
                                <div class="front-title-part">
                                    <h3 class="title"><a>24x7 support<br>&nbsp;</a></h3>
                                </div>
                                <div class="front-desc-part">
                                    <p>
                                        24 hours dedicated support team for travel agents and DMCs during the integration and post production.<br>&nbsp;
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="back-front">
                            <div class="back-front-content">
                               <div class="back-title-part">
                                  <h3 class="back-title"><a href="data-center.html">Data Center</a></h3>
                               </div>
                               <div class="back-desc-part">
                                  <p class="back-desc">We denounce with righteous indignation and dislike men who are so beguiled and demo ralized your data.</p>
                               </div>
                               <div class="back-btn-part">
                                  <a class="readon view-more" href="data-center.html">Get In Touch</a>
                               </div>
                            </div>
                         </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!--end #gallery.ts-block-->

<section class="ts-block xmb-5 xmt-5">
    <div class="container">
        <div class="ts-titlex">
            <h2>Technology we use for website</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-lg-3 md-mb-30">
                <div class="technology-item">
                    <div class="technology-icon">
                        <img src="assets\img\webicons\lang\php.png" alt="Images">
                    </div>
                    <div class="technology-content">
                        <h3 class="technology-title">PHP</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 md-mb-30">
                <div class="technology-item">
                    <div class="technology-icon">
                        <img src="assets\img\webicons\lang\react.png" alt="Images">
                    </div>
                    <div class="technology-content">
                        <h3 class="technology-title">React</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 md-mb-30">
                <div class="technology-item">
                    <div class="technology-icon">
                        <img src="assets\img\webicons\lang\angular.png" alt="Images">
                    </div>
                    <div class="technology-content">
                        <h3 class="technology-title">Angular</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="technology-item">
                    <div class="technology-icon">
                        <img src="assets\img\webicons\lang\ci.png" alt="Images" style="width: 50px;">
                    </div>
                    <div class="technology-content">
                        <h3 class="technology-title">Code<strong>Igniter</strong></h3>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<app-enquiryform></app-enquiryform>