import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'Access-Control-Allow-Origin': 'http:// localhost:4200/',
  })
};

@Component({
  selector: 'app-enquiryform',
  templateUrl: './enquiryform.component.html',
  styleUrls: ['./enquiryform.component.css']
})
export class EnquiryformComponent implements OnInit {

  enquiry_response = false;
  EnquiryForm: FormGroup;

  constructor(private http:HttpClient, private frmBuilder:FormBuilder) {
    this.EnquiryForm = frmBuilder.group({
      username: ['', Validators.required],
      useremail: ['', [Validators.required,Validators.email]],
      usermob: ['', [Validators.required,]],
      usercomp: ['', [Validators.required,]],
      userwano: ['', []],
      userabtstart: ['', [Validators.required,]],
      usermsg: ['', [Validators.required,]],
    });
  }

  reset(){
    this.EnquiryForm.setValue({
      'username' : '',
      'useremail' : '',
      'usermob' : '',
      'usercomp' : '',
      'userwano' : '',
      'userabtstart' : '',
      'usermsg' : '',
    });
  }

  submitForm(EnquiryForm){
    if (this.EnquiryForm.valid) {
      const body=(EnquiryForm.value);
      this.http.post('/apis/enquiry', body ,httpOptions).subscribe(
        (response)=>{
          // console.log(response);
          this.enquiry_response=true;
          this.reset();
          setTimeout(() => {
            this.enquiry_response=false;
          }, 5000);
        },
        (err) => {
          // console.log(err);
        }
      );
    }
  }

  ngOnInit(): void {
  }

}
