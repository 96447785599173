<main id="ts-content" style="margin-top: 100px;">

    <!--HERO CONTENT ****************************************************************************************-->
    <div class="container align-self-center">
        <div class="row align-items-center" id="bannercontentabout">
            <div class="col-sm-7">
                <h3 style="color: white;">
                    We love what we do and this is evident in the work we produce as well as the excellent relationships we have developed with our clients.
                </h3>

            </div>
            <!--end col-sm-7 col-md-7-->
            <div class="col-sm-5 d-none d-sm-block">
                <div class="owl-carousesls text-center" data-owl-nav="1" data-owl-loop="1">

                </div>
            </div>
            <!--end col-sm-5 col-md-5 col-xl-5-->
        </div>
        <!--end row-->
        <div class="ts-background" id="bannerbgabout" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3" style="height: 75%;">
            <div class="ts-background-image ts-parallax-element" data-bg-color="#4345d2" data-bg-image="assets/img/bg-pattern.jpg" data-bg-blend-mode="overlay"></div>
        </div>
    </div>
</main>

<section class="ts-block" data-bg-size="inherit" data-bg-position="left" data-bg-repeat="no-repeat">
    <div class="container">
        <!--end ts-title-->
        <div class="row align-items-center">
            <div class="col-md-5-5 col-xl-5-5" data-animate="ts-fadeInUp" data-delay="0.1s">
                <div class="ts-svg ts-svg__organic-shape-02 ts-background-size-contain">
                    <img src="assets\img\about2.png" class="mw-100 ts-border-radius__md" alt="">
                </div>
            </div>
            <div class="col-md-0-5 col-xl-0-5"></div>
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100">
                <p class="justify">
                    <span style="font-size: 1.3rem;color: #5eeef0;">Our journey started</span> in the year 2018 by our founders Mr. Vivek Kumar Gupta & O.S.K Singh, came up with an idea to reinvent the travel technology for the better future of
                    travel industry. Over the years we have helped clients with our technologies. Our aim has always been to deliver cutting edge web design coupled with rock solid web programming. We specialise in developing bespoke web applications
                    & other platforms. Every web project is built primarily using open source technologies and are tailor made to fit unique requirements. We work with different types of organisations across a range of industries including companies,
                    charities, not-for-profits, business schools, and individuals.
                    <br><br> We are passionate about web technologies and constantly keep an eye out for new technologies that make us more efficient. We truly believe we can make an impact with our technology in this business. We are big believers in
                    the huge benefits of public and hybrid clouds. Cloud computing empowers us to deliver secure, scalable and reliable applications that reach millions of customers. Being an independent agency, we are also happy to work with systems
                    you currently use. All our clients have very different requirements and we try to be as flexible as possible to offer the most suitable solution.
                </p>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="ts-block" data-bg-color="#ededed">
    <div class="container">
        <div class="row">
            <div class="col-md-5-5 col-xl-5-5">
                <p style="font-size: 1rem;">
                    Ewayorbit is a travel portal & software development company that designs, develops, and maintains solutions that can make an impact through technologies.
                </p>
            </div>
            <div class="col-md-1 col-xl-1"></div>
            <div class="col-md-5-5 col-xl-5-5">
                <p style="font-size: 1rem;">
                    We integrate our unique experience with our clients ideas which transform affordable solutions.<br><br> We can handle your entire software development cycle for the best customer experience.
                </p>
            </div>
        </div>
    </div>
</section>

<section class="ts-block text-center" id="set4">
    <div class="container">
        <div class="ts-title">
            <h2>What we believe</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-xl-4">
                        <figure data-animate="ts-fadeInUp">
                            <figure class="icon mb-5 p-2">
                                <img src="assets\img\autonomy.png" alt="">
                                <div class="ts-svg ts-svg__organic-shape-01" data-animate="ts-zoomInShort"></div>
                            </figure>
                        </figure>
                    </div>
                    <div class="col-sm-8 col-md-8 col-xl-8 text-left">
                        <h4>Autonomy & collaboration</h4>
                        <p>
                            Being a small team we need every team member to act like a leader in their area. We push each of us to stay proactive and drive projects forward. While working independently each of us collaborates and supports other. Hard work focused on results.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-xl-4">
                        <figure data-animate="ts-fadeInUp">
                            <figure class="icon mb-5 p-2">
                                <img src="assets\img\openness.png" alt="">
                                <div class="ts-svg ts-svg__organic-shape-02" data-animate="ts-zoomInShort"></div>
                            </figure>
                        </figure>
                    </div>
                    <div class="col-sm-8 col-md-8 col-xl-8 text-left">
                        <h4>Openness</h4>
                        <p>
                            No matter how many projects we have delivered, we always have so many things to learn, so it’s important to remember it and keep your ego small. We keep ourselves opened to critique, positive and optimistic.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-xl-4">
                        <figure data-animate="ts-fadeInUp">
                            <figure class="icon mb-5 p-2">
                                <img src="assets\img\ownership.png" alt="">
                                <div class="ts-svg ts-svg__organic-shape-02" data-animate="ts-zoomInShort"></div>
                            </figure>
                        </figure>
                    </div>
                    <div class="col-sm-8 col-md-8 col-xl-8 text-left">
                        <h4>Ownership</h4>
                        <p>
                            The design is done when a project is live and meets its goals or solves the addressed problem. To get there we step out from our design area and collaborate with other teams to make sure the project is launched in the best way possible.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-xl-6">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-xl-4">
                        <figure data-animate="ts-fadeInUp">
                            <figure class="icon mb-5 p-2">
                                <img src="assets\img\growth.png" alt="">
                                <div class="ts-svg ts-svg__organic-shape-03" data-animate="ts-zoomInShort"></div>
                            </figure>
                        </figure>
                    </div>
                    <div class="col-sm-8 col-md-8 col-xl-8 text-left">
                        <h4>Growth</h4>
                        <p>
                            We expect personal and professional growth from each of us. From learning a new tool or taking bigger responsibilities, we help and provide support for stretching ourselves and leaving our comfort zone.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<!--OUR TEAM ********************************************************************************************--
<section id="our-team" class="ts-block text-center" data-bg-color="#ededed">
    <div class="container">
        <div class="ts-title">
            <h2>Leadership Team</h2>
        </div>
        
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="card" data-animate="ts-fadeInUp">
                    <div class="ts-card__image ts-img-into-bg">
                        <img class="card-img-top" src="assets\img\person-01.jpg" alt="Card image cap">
                    </div>
                    
                    <div class="card-body">
                        <h5 class="mb-1">Anita Matthews</h5>
                        <h6 class="ts-opacity__50">Company CEO</h6>
                    </div>
                </div>
                
            </div>
            
            <div class="col-sm-6 col-lg-3">
                <div class="card" data-animate="ts-fadeInUp" data-delay=".1s">
                    <div class="ts-card__image ts-img-into-bg">
                        <img class="card-img-top" src="assets\img\person-02.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <h5 class="mb-1">Levi Masters</h5>
                        <h6 class="ts-opacity__50">Investment Specialist</h6>
                    </div>
                </div>
                
            </div>
            
            <div class="col-sm-6 col-lg-3">
                <div class="card" data-animate="ts-fadeInUp" data-delay=".2s">
                    <div class="ts-card__image ts-img-into-bg">
                        <img class="card-img-top" src="assets\img\person-03.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <h5 class="mb-1">John Doe</h5>
                        <h6 class="ts-opacity__50">Head Of Marketing</h6>
                    </div>
                </div>
                
            </div>
            
            <div class="col-sm-6 col-lg-3">
                <div class="card" data-animate="ts-fadeInUp" data-delay=".3s">
                    <div class="ts-card__image ts-img-into-bg">
                        <img class="card-img-top" src="assets\img\person-04.jpg" alt="Card image cap">
                    </div>
                    <div class="card-body">
                        <h5 class="mb-1">Lisa Haley</h5>
                        <h6 class="ts-opacity__50">Customer Support</h6>
                    </div>
                </div>
                
            </div>
            
        </div>
        
    </div>
    
</section>-->

<section id="owl-lang" class="ts-block text-center" data-bg-color="#ededed">
    <div class="container">
        <div class="owl-carousel" data-owl-items="5" data-owl-autoplay="1" data-owl-loop="1">
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\html.JPG">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\css.JPG">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\reactjs.png">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\js-jquery.png">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\php.JPG">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\mysql.JPG">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\codeigniter.png">
                </a>
            </div>
            <div class="item">
                <a class="hover-effect">
                    <img src="assets\img\technologies\android.jpg">
                </a>
            </div>
        </div>
    </div>
</section>

<app-enquiryform></app-enquiryform>