<section id="pricing" class="ts-block" data-bg-color="#ededed">
    <div class="container">
        <div class="ts-title text-center">
            <h2>Whitelabel Travel Website</h2>
        </div>
        <!--end ts-title-->
        <div class="row no-gutters ts-cards-same-height">
            <!--Price Box-->
            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box">
                    <div class="card-header p-0">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#1b1464">Silver</h5>
                        <!-- <div class="ts-title py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">Free</h3>
                            <small class="ts-opacity__50">forever</small>
                        </div> -->
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>Live Chat</li>
                            <li>10 Extra Pages</li>
                            <li>1 Email ID<br>(5GB Space/Email ID)</li>
                            <li>Color Change</li>
                            <li>Agent Module</li>
                            <li>100 Agents</li>
                            <li>Link to Existing Website</li>
                            <li><s class="ts-opacity__50">SEO</s></li>
                            <li>Travel Services<br>Any 1 from Flight, Hotel & Tour</li>
                            <li>Incentives<br>
                                <strong>Flight - </strong>Net Fare<br>
                                <strong>Hotel - </strong>Net Fare<br>
                                <strong>Tour CMS - </strong>Markup<br>
                            </li>
                            <li>100% Extra Markup</li>
                            <li>Domain, Hosting &amp; Server Included</li>
                            <li>SSL integration</li>
                            <li>Admin Pannel</li>
                            <li><s class="ts-opacity__50">Sub Admin</s></li>
                            <li>Whatsapp Button Integration</li>
                            <li>Social Media Integration</li>
                            <li>Multicurrency</li>
                            <li>Multilingual</li>
                            <li><s class="ts-opacity__50">Google Analytics</s></li>
                            <li>Call Back Request Button</li>
                            <li>Maintenance Cost - 20% of registration(Yearly)<br>After 1st Year</li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-outline-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end price-box col-md-4-->

            <!--Price Box Promoted-->
            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box">
                    <div class="card-header p-0">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#1b1464">Gold</h5>
                        <!-- <div class="ts-title py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">
                                <sup>$</sup>9,99
                            </h3>
                            <small class="ts-opacity__50">per month</small>
                        </div> -->
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>Live Chat</li>
                            <li>20 Extra Pages</li>
                            <li>1 Email ID<br>(5GB Space/Email ID)</li>
                            <li>Color Change</li>
                            <li>Agent Module</li>
                            <li>200 Agents</li>
                            <li>Link to Existing Website</li>
                            <li>SEO</li>
                            <li>Travel Services<br>Any 2 from Flight, Hotel & Tour</li>
                            <li>Incentives<br>
                                <strong>Flight - </strong>Net Fare<br>
                                <strong>Hotel - </strong>Net Fare<br>
                                <strong>Tour CMS - </strong>Markup<br>
                            </li>
                            <li>100% Extra Markup</li>
                            <li>Domain, Hosting &amp; Server Included</li>
                            <li>SSL integration</li>
                            <li>Admin Pannel</li>
                            <li>Sub Admin</li>
                            <li>Whatsapp Button Integration</li>
                            <li>Social Media Integration</li>
                            <li>Multicurrency</li>
                            <li>Multilingual</li>
                            <li>Google Analytics</li>
                            <li>Call Back Request Button</li>
                            <li>Maintenance Cost - 20% of registration(Yearly)<br>After 1st Year</li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-outline-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end price-box col-md-4-->

            <!--Price Box-->
            <div class="col-sm-4 col-lg-4">
                <div class="card text-center ts-price-box ts-price-box__promoted">
                    <div class="card-header p-0" data-bg-color="#d96270">
                        <h5 class="mb-0 py-3 text-white" data-bg-color="#f26d7d">Diamond</h5>
                        <!-- <div class="ts-title text-white py-5 mb-0">
                            <h3 class="mb-0 font-weight-normal">
                                <sup>$</sup>19,99
                            </h3>
                            <small class="ts-opacity__50">per month</small>
                        </div> -->
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-0">
                        <ul class="list-unstyled ts-list-divided">
                            <li>Live Chat</li>
                            <li>30 Extra Pages</li>
                            <li>2 Email ID<br>(5GB Space/Email ID)</li>
                            <li>Color Change</li>
                            <li>Agent Module</li>
                            <li>300 Agents</li>
                            <li>Link to Existing Website</li>
                            <li>SEO</li>
                            <li>Travel Services<br>All 3 from Flight, Hotel & Tour</li>
                            <li>Incentives<br>
                                <strong>Flight - </strong>Net Fare<br>
                                <strong>Hotel - </strong>Net Fare<br>
                                <strong>Tour CMS - </strong>Markup<br>
                            </li>
                            <li>100% Extra Markup</li>
                            <li>Domain, Hosting &amp; Server Included</li>
                            <li>SSL integration</li>
                            <li>Admin Pannel</li>
                            <li>Sub Admin</li>
                            <li>Whatsapp Button Integration</li>
                            <li>Social Media Integration</li>
                            <li>Multicurrency</li>
                            <li>Multilingual</li>
                            <li>Google Analytics</li>
                            <li>Call Back Request Button</li>
                            <li>Maintenance Cost - 20% of registration(Yearly)<br>After 1st Year</li>
                        </ul>
                        <!--end list-->
                    </div>
                    <!--end card-body-->
                    <div class="card-footer bg-transparent pt-0 ts-border-none">
                        <a href="#" class="btn btn-primary disabled" disabled>Select Now</a>
                    </div>
                </div>
                <!--end card-->
            </div>
            <!--end price-box col-md-4-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="pricing_top">
    <div class="container">
        <div class="ts-title text-center mb-0">
            <h2 class="text-white mb-0" style="font-size: 2rem;">Already have a website?</h2>
            <p class="text-white mb-0" style="font-size: 18px;">Integrate with our customisable booking engine and allow customers to book directly on your website.<br> Your brand. Your logo. Your colors.</p>
        </div>
    </div>
</section>

<app-enquiryform></app-enquiryform>