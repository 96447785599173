<br>
<br>
<main  class="ts-block bg-grey pt-50 pb-50" style="transform: none;">
    <div class="pb-50" style="transform: none;">
        <div class="container" style="transform: none;">
            <div class="row" style="transform: none;">
                <div class="col-lg-8">
                    <div class="single-content2">
                        <div class="entry-header entry-header-style-1 mb-50">
                            <h1 class="entry-title mb-30 font-weight-900">
                                Helpful Tips for Working from Home as a Freelancer
                            </h1>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="entry-meta align-items-center meta-2 font-small color-muted">
                                        <p class="mb-5">
                                            <a class="author-avatar" href="#"><img class="img-circle" src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-3.jpg" alt=""></a>
                                            By <a href="http://demos.alithemes.com/html/stories/demo/author.html"><span class="author-name font-weight-bold">Barbara Cartland</span></a>
                                        </p>
                                        <span class="mr-10"> 15 April 2020</span>
                                        <span class="has-dot"> 8 mins read</span>
                                    </div>
                                </div>
                                <div class="col-md-6 text-right d-none d-md-inline">
                                    <ul class="header-social-network d-inline-block list-inline mr-15">
                                        <li class="list-inline-item text-muted"><span>Share this: </span></li>
                                        <li class="list-inline-item"><a class="social-icon fb text-xs-center" target="_blank" href="#"><i class="elegant-icon social_facebook"></i></a></li>
                                        <li class="list-inline-item"><a class="social-icon tw text-xs-center" target="_blank" href="#"><i class="elegant-icon social_twitter "></i></a></li>
                                        <li class="list-inline-item"><a class="social-icon pt text-xs-center" target="_blank" href="#"><i class="elegant-icon social_pinterest "></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!--end single header-->
                        <figure class="image mb-30 m-auto text-center border-radius-10">
                            <img class="border-radius-10" src="assets\img\blog-01.jpg" alt="post-title" style="width: 100%;border-radius: 30px;">
                        </figure>
                        <!--figure-->
                        <article class="entry-wraper mb-50">
                            <div class="excerpt mb-30">
                                <p>Gosh jaguar ostrich quail one 
                                    excited dear hello and bound and the and bland moral misheard roadrunner
                                     flapped lynx far that and jeepers giggled far and far bald that 
                                    roadrunner python inside held shrewdly the manatee.</p>
                            </div>
                            <div class="entry-main-content dropcap wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                <p>Fretful human far recklessly 
                                    while caterpillar well a well blubbered added one a some far whispered 
                                    rampantly whispered while irksome far clung irrespective wailed more 
                                    rosily and where saluted while black dear so yikes as considering recast
                                     to some crass until.</p>
                                <hr class="wp-block-separator is-style-dots">
                                <p>Thanks sniffed in hello after in 
                                    foolhardy and some far purposefully much one at the much conjointly 
                                    leapt skimpily that quail sheep some goodness <a href="#">nightingale</a> the instead exited expedient up far ouch mellifluous altruistic and and lighted more instead much when ferret but the.</p>
                                <figure class="wp-block-gallery columns-3 wp-block-image">
                                    <ul class="blocks-gallery-grid">
                                        <li class="blocks-gallery-item"><a href="#"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-2.jpg" alt=""></a></li>
                                        <li class="blocks-gallery-item"><a href="#"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-3.jpg" alt=""></a></li>
                                        <li class="blocks-gallery-item"><a href="#"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-4.jpg" alt=""></a></li>
                                    </ul>
                                    <figcaption> <i class="ti-credit-card mr-5"></i>Image credit: Behance </figcaption>
                                </figure>
                                <hr class="section-divider">
                                <p>Yet more some certainly yet alas abandonedly whispered <a href="#">intriguingly</a><sup><a href="#">[2]</a></sup> well extensive one howled talkative admonishingly below a rethought overlaid dear gosh activated less <a href="#">however</a> hawk yet oh scratched ostrich some outside crud irrespective lightheartedly and much far amenably that the elephant since when.</p>
                                <h2>The Guitar Legends</h2>
                                <p>Furrowed this in the upset <a href="#">some across</a><sup><a href="#">[3]</a></sup> tiger oh loaded house gosh whispered <a href="#">faltering alas</a><sup><a href="#">[4]</a></sup>
                                 ouch cuckoo coward in scratched undid together bit fumblingly so 
                                besides salamander heron during the jeepers hello fitting jauntily much 
                                smoothly globefish darn blessedly far so along bluebird leopard and.</p>
                                <blockquote>
                                    <p>Integer eu faucibus <a href="#">dolor</a><sup><a href="#">[5]</a></sup>.
                                             Ut venenatis tincidunt diam elementum imperdiet. Etiam accumsan semper 
                                            nisl eu congue. Sed aliquam magna erat, ac eleifend lacus rhoncus in.</p>
                                </blockquote>
                                <p>Fretful human far recklessly 
                                    while caterpillar well a well blubbered added one a some far whispered 
                                    rampantly whispered while irksome far clung irrespective wailed more 
                                    rosily and where saluted while black dear so yikes as considering recast
                                     to some crass until cow much less and rakishly overdrew consistent for 
                                    by responsible oh one hypocritical less bastard hey oversaw zebra 
                                    browbeat a well.</p>
                                <h3>Getting Crypto Rich</h3>
                                <hr class="wp-block-separator is-style-wide">
                                <div class="wp-block-image">
                                    <figure class="alignleft is-resized">
                                        <img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-11.jpg">
                                        <figcaption> And far contrary smoked some contrary among stealthy</figcaption>
                                    </figure>
                                </div>
                                <p>And far contrary smoked some 
                                    contrary among stealthy engagingly suspiciously a cockatoo far circa 
                                    sank dully lewd slick cracked llama the much gecko yikes more squirrel 
                                    sniffed this and the the much within uninhibited this abominable a 
                                    blubbered overdid foresaw through alas the pessimistic.</p>
                                                                        <p>Gosh jaguar ostrich quail one 
                                    excited dear hello and bound and the and bland moral misheard roadrunner
                                     flapped lynx far that and jeepers giggled far and far bald that 
                                    roadrunner python inside held shrewdly the manatee.</p>
                                                                        <hr class="section-divider">
                                                                        <p>Thanks sniffed in hello after in 
                                    foolhardy and some far purposefully much one at the much conjointly 
                                    leapt skimpily that quail sheep some goodness nightingale the instead 
                                    exited expedient up far ouch mellifluous altruistic and and lighted more
                                     instead much when ferret but the.</p>
                                <!--Begin Subcrible-->
                                <div class="border-radius-10 border bg-white mb-30 p-30 wow fadeIn" style="visibility: visible; animation-name: fadeIn;">
                                    <div class="row justify-content-between">
                                        <div class="col-md-5 mb-2 mb-md-0">
                                            <h5 class="font-weight-bold secondfont mb-30 mt-0">Become a member</h5>
                                            <p class="font-small">Get the latest news right in your inbox. We never spam!</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <input type="text" class="form-control" placeholder="Enter your e-mail address">
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <button type="submit" class="btn btn-primary btn-block">Subscribe</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--End Subcrible-->
                                <p>Yet more some certainly yet alas 
                                    abandonedly whispered intriguingly well extensive one howled talkative 
                                    admonishingly below a rethought overlaid dear gosh activated less 
                                    however hawk yet oh scratched ostrich some outside crud irrespective 
                                    lightheartedly and much far amenably that the elephant since when.</p>
                            </div>
                            <div class="entry-bottom mt-50 mb-30 wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                <div class="tags">
                                    <span>Tags: </span>
                                    <a href="http://demos.alithemes.com/html/stories/demo/category.html" rel="tag">deer</a>
                                    <a href="http://demos.alithemes.com/html/stories/demo/category.html" rel="tag">nature</a>
                                    <a href="http://demos.alithemes.com/html/stories/demo/category.html" rel="tag">conserve</a>
                                </div>
                            </div>
                            <div class="single-social-share clearfix wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                <div class="entry-meta meta-1 font-small color-grey float-left mt-10">
                                    <span class="hit-count mr-15"><i class="elegant-icon icon_comment_alt mr-5"></i>182 comments</span>
                                    <span class="hit-count mr-15"><i class="elegant-icon icon_like mr-5"></i>268 likes</span>
                                    <span class="hit-count"><i class="elegant-icon icon_star-half_alt mr-5"></i>Rate: 9/10</span>
                                </div>
                                <ul class="header-social-network d-inline-block list-inline float-md-right mt-md-0 mt-4">
                                    <li class="list-inline-item text-muted"><span>Share this: </span></li>
                                    <li class="list-inline-item"><a class="social-icon fb text-xs-center" target="_blank" href="#"><i class="elegant-icon social_facebook"></i></a></li>
                                    <li class="list-inline-item"><a class="social-icon tw text-xs-center" target="_blank" href="#"><i class="elegant-icon social_twitter "></i></a></li>
                                    <li class="list-inline-item"><a class="social-icon pt text-xs-center" target="_blank" href="#"><i class="elegant-icon social_pinterest "></i></a></li>
                                </ul>
                            </div>
                            <!--author box-->
                            <div class="author-bio p-30 mt-50 border-radius-10 bg-white wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                <div class="author-image mb-30">
                                    <a href="http://demos.alithemes.com/html/stories/demo/author.html"><img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-3.jpg" alt="" class="avatar"></a>
                                </div>
                                <div class="author-info">
                                    <h4 class="font-weight-bold mb-20"><span class="vcard author"><span class="fn"><a href="http://demos.alithemes.com/html/stories/demo/author.html" title="Posted by Barbara Cartland" rel="author">Barbara Cartland</a></span></span>
                                    </h4>
                                    <h5 class="text-muted">About author</h5>
                                    <div class="author-description text-muted">You should write because you love the shape of stories and sentences and the creation of different words on a page. </div>
                                    <a href="http://demos.alithemes.com/html/stories/demo/author.html" class="author-bio-link mb-md-0 mb-3">View all posts (125)</a>
                                    
                                </div>
                            </div>
                            <!--related posts-->
                            <!--More posts-->
                            <div class="single-more-articles border-radius-5">
                                <div class="widget-header-2 position-relative mb-30">
                                    <h5 class="mt-5 mb-30">You might be interested in</h5>
                                    <button class="single-more-articles-close"><i class="elegant-icon icon_close"></i></button>
                                </div>
                                <div class="post-block-list post-module-1 post-module-5">
                                    <ul class="list-post">
                                        <li class="mb-30">
                                            <div class="d-flex hover-up-2 transition-normal">
                                                <div class="post-thumb post-thumb-80 d-flex mr-15 border-radius-5 img-hover-scale overflow-hidden">
                                                    <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                        <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-1.jpg" alt="">
                                                    </a>
                                                </div>
                                                <div class="post-content media-body">
                                                    <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">The Best Time to Travel to Cambodia</a></h6>
                                                    <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                        <span class="post-on">27 Jan</span>
                                                        <span class="post-by has-dot">13k views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="mb-10">
                                            <div class="d-flex hover-up-2 transition-normal">
                                                <div class="post-thumb post-thumb-80 d-flex mr-15 border-radius-5 img-hover-scale overflow-hidden">
                                                    <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                        <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-2.jpg" alt="">
                                                    </a>
                                                </div>
                                                <div class="post-content media-body">
                                                    <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">20 Photos to Inspire You to Visit Cambodia</a></h6>
                                                    <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                        <span class="post-on">27 August</span>
                                                        <span class="post-by has-dot">14k views</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!--Comments-->
                            <div class="comments-area">
                                <div class="widget-header-2 position-relative mb-30">
                                    <h5 class="mt-5 mb-30">Comments</h5>
                                </div>
                                <div class="comment-list wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                    <div class="single-comment justify-content-between d-flex">
                                        <div class="user justify-content-between d-flex">
                                            <div class="thumb">
                                                <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-4.jpg" alt="">
                                            </div>
                                            <div class="desc">
                                                <p class="comment">
                                                    Vestibulum 
                                                        euismod, leo eget varius gravida, eros enim interdum urna, non rutrum 
                                                        enim ante quis metus. Duis porta ornare nulla ut bibendum
                                                </p>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Rosie</a>
                                                        </h5>
                                                        <p class="date">6 minutes ago </p>
                                                    </div>
                                                    <div class="reply-btn">
                                                        <a href="#" class="btn-reply">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-list wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                    <div class="single-comment justify-content-between d-flex">
                                        <div class="user justify-content-between d-flex">
                                            <div class="thumb">
                                                <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-2.jpg" alt="">
                                            </div>
                                            <div class="desc">
                                                <p class="comment">
                                                    Sed ac lorem 
                                                    felis. Ut in odio lorem. Quisque magna dui, maximus ut commodo sed, 
                                                    vestibulum ac nibh. Aenean a tortor in sem tempus auctor
                                                                                                        </p>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Agatha Christie</a>
                                                        </h5>
                                                        <p class="date">December 4, 2020 at 3:12 pm </p>
                                                    </div>
                                                    <div class="reply-btn">
                                                        <a href="#" class="btn-reply">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-comment depth-2 justify-content-between d-flex mt-50">
                                        <div class="user justify-content-between d-flex">
                                            <div class="thumb">
                                                <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author.jpg" alt="">
                                            </div>
                                            <div class="desc">
                                                <p class="comment">
                                                    Sed ac lorem 
                                                    felis. Ut in odio lorem. Quisque magna dui, maximus ut commodo sed, 
                                                    vestibulum ac nibh. Aenean a tortor in sem tempus auctor
                                                </p>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Steven</a>
                                                        </h5>
                                                        <p class="date">December 4, 2020 at 3:12 pm </p>
                                                    </div>
                                                    <div class="reply-btn">
                                                        <a href="#" class="btn-reply">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="comment-list wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                    <div class="single-comment justify-content-between d-flex">
                                        <div class="user justify-content-between d-flex">
                                            <div class="thumb">
                                                <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-3.jpg" alt="">
                                            </div>
                                            <div class="desc">
                                                <p class="comment">
                                                    Donec in 
                                                    ullamcorper quam. Aenean vel nibh eu magna gravida fermentum. Praesent 
                                                    eget nisi pulvinar, sollicitudin eros vitae, tristique odio.
                                                </p>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Danielle Steel</a>
                                                        </h5>
                                                        <p class="date">December 4, 2020 at 3:12 pm </p>
                                                    </div>
                                                    <div class="reply-btn">
                                                        <a href="#" class="btn-reply">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--comment form-->
                            <div class="comment-form wow fadeIn  animated" style="visibility: visible; animation-name: fadeIn;">
                                <div class="widget-header-2 position-relative mb-30">
                                    <h5 class="mt-5 mb-30">Leave a Reply</h5>
                                </div>
                                <form class="form-contact comment_form" action="#" id="commentForm">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <textarea class="form-control w-100" name="comment" id="comment" cols="30" rows="9" placeholder="Write Comment"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input class="form-control" name="name" id="name" type="text" placeholder="Name">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <input class="form-control" name="email" id="email" type="email" placeholder="Email">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input class="form-control" name="website" id="website" type="text" placeholder="Website">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <button type="submit" class="btn button button-contactForm">Post Comment</button>
                                    </div>
                                </form>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="col-lg-4 primary-sidebar sticky-sidebar" style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">
                    
                <div class="theiaStickySidebar" style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 964.6px;"><div class="widget-area">
                        <div class="sidebar-widget widget-about mb-50 pt-30 pr-30 pb-30 pl-30 bg-white border-radius-5 has-border  wow fadeInUp  animated" style="visibility: visible; animation-name: fadeInUp;">
                            <img class="about-author-img mb-25" src="Stories%20-Single%20post%20with%20right%20sidebar_files/author.jpg" alt="">
                            <h5 class="mb-20">Hello, I'm Steven</h5>
                            <p class="font-medium text-muted">Hi, 
                                                I’m Stenven, a Florida native, who left my career in corporate wealth 
                                                management six years ago to embark on a summer of soul searching that 
                                                would change the course of my life forever.</p>
                            <strong>Follow me: </strong>
                            <ul class="header-social-network d-inline-block list-inline color-white mb-20">
                                <li class="list-inline-item"><a class="fb" href="#" target="_blank" title="Facebook"><i class="elegant-icon social_facebook"></i></a></li>
                                <li class="list-inline-item"><a class="tw" href="#" target="_blank" title="Tweet now"><i class="elegant-icon social_twitter"></i></a></li>
                                <li class="list-inline-item"><a class="pt" href="#" target="_blank" title="Pin it"><i class="elegant-icon social_pinterest"></i></a></li>
                            </ul>
                        </div>
                        <div class="sidebar-widget widget-latest-posts mb-50 wow fadeInUp  animated" style="visibility: visible; animation-name: fadeInUp;">
                            <div class="widget-header-1 position-relative mb-30">
                                <h5 class="mt-5 mb-30">Most popular</h5>
                            </div>
                            <div class="post-block-list post-module-1">
                                <ul class="list-post">
                                    <li class="mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-content media-body">
                                                <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">Spending Some Quality Time with Kids? It’s Possible</a></h6>
                                                <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                    <span class="post-on">05 August</span>
                                                    <span class="post-by has-dot">150 views</span>
                                                </div>
                                            </div>
                                            <div class="post-thumb post-thumb-80 d-flex ml-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-6.jpg" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-content media-body">
                                                <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">Relationship Podcasts are Having “That” Talk</a></h6>
                                                <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                    <span class="post-on">12 August</span>
                                                    <span class="post-by has-dot">6k views</span>
                                                </div>
                                            </div>
                                            <div class="post-thumb post-thumb-80 d-flex ml-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-7.jpg" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-content media-body">
                                                <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">Here’s How to Get the Best Sleep at Night</a></h6>
                                                <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                    <span class="post-on">15 August</span>
                                                    <span class="post-by has-dot">16k views</span>
                                                </div>
                                            </div>
                                            <div class="post-thumb post-thumb-80 d-flex ml-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-2.jpg" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-content media-body">
                                                <h6 class="post-title mb-15 text-limit-2-row font-medium"><a href="http://demos.alithemes.com/html/stories/demo/single.html">America’s Governors Get Tested for a Virus That Is Testing Them</a></h6>
                                                <div class="entry-meta meta-1 float-left font-x-small text-uppercase">
                                                    <span class="post-on">12 August</span>
                                                    <span class="post-by has-dot">3k views</span>
                                                </div>
                                            </div>
                                            <div class="post-thumb post-thumb-80 d-flex ml-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-3.jpg" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="sidebar-widget widget-latest-posts mb-50 wow fadeInUp  animated" style="visibility: visible; animation-name: fadeInUp;">
                            <div class="widget-header-1 position-relative mb-30">
                                <h5 class="mt-5 mb-30">Last comments</h5>
                            </div>
                            <div class="post-block-list post-module-2">
                                <ul class="list-post">
                                    <li class="mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-thumb post-thumb-64 d-flex mr-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-2.jpg" alt="">
                                                </a>
                                            </div>
                                            <div class="post-content media-body">
                                                <p class="mb-10"><a href="http://demos.alithemes.com/html/stories/demo/author.html"><strong>David</strong></a>
                                                    <span class="ml-15 font-small text-muted has-dot">16 Jan 2020</span>
                                                </p>
                                                <p class="text-muted font-small">A writer is someone for whom writing is more difficult than...</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="mb-30 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-thumb post-thumb-64 d-flex mr-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/author-3.jpg" alt="">
                                                </a>
                                            </div>
                                            <div class="post-content media-body">
                                                <p class="mb-10"><a href="http://demos.alithemes.com/html/stories/demo/author.html"><strong>Kokawa</strong></a>
                                                    <span class="ml-15 font-small text-muted has-dot">12 Feb 2020</span>
                                                </p>
                                                <p class="text-muted font-small">Striking pewter studded epaulettes silver zips</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <div class="d-flex bg-white has-border p-25 hover-up transition-normal border-radius-5">
                                            <div class="post-thumb post-thumb-64 d-flex mr-15 border-radius-5 img-hover-scale overflow-hidden">
                                                <a class="color-white" href="http://demos.alithemes.com/html/stories/demo/single.html">
                                                    <img src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-1.jpg" alt="">
                                                </a>
                                            </div>
                                            <div class="post-content media-body">
                                                <p class="mb-10"><a href="http://demos.alithemes.com/html/stories/demo/author.html"><strong>Tsukasi</strong></a>
                                                    <span class="ml-15 font-small text-muted has-dot">18 May 2020</span>
                                                </p>
                                                <p class="text-muted font-small">Workwear bow detailing a slingback buckle strap</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="sidebar-widget widget_instagram wow fadeInUp  animated" style="visibility: visible; animation-name: fadeInUp;">
                            <div class="widget-header-1 position-relative mb-30">
                                <h5 class="mt-5 mb-30">Instagram</h5>
                            </div>
                            <div class="instagram-gellay">
                                <ul class="insta-feed">
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-3.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-1.jpg" alt=""></a>
                                    </li>
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-4.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-2.jpg" alt=""></a>
                                    </li>
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-5.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-3.jpg" alt=""></a>
                                    </li>
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-3.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-4.jpg" alt=""></a>
                                    </li>
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-4.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-5.jpg" alt=""></a>
                                    </li>
                                    <li>
                                        <a href="http://demos.alithemes.com/html/stories/demo/assets/imgs/thumbnail-5.jpg" class="play-video" data-animate="zoomIn" data-duration="1.5s" data-delay="0.1s"><img class="border-radius-5" src="Stories%20-Single%20post%20with%20right%20sidebar_files/thumb-6.jpg" alt=""></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div></div></div>
            </div>
        </div>
    </div>
</main>