import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    // 'Access-Control-Allow-Origin': 'http:// localhost:4200/',
  })
};


declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})

export class ContactusComponent implements OnInit {
  
  contact_form_response = false;
  ContactForm: FormGroup;

  constructor(private http:HttpClient, private frmBuilder:FormBuilder) {
    this.ContactForm = frmBuilder.group({
      contacttype: ['', [Validators.required,]],
      username: ['', Validators.required],
      useremail: ['', [Validators.required,Validators.email]],
      usercomp: ['', [Validators.required,]],
      usermob: ['', [Validators.required,]],
      usercountry: ['', [Validators.required,]],
      userwebsite: ['', []],
      usermsg: ['', [Validators.required,]],
    });
  }
  
  reset(){
    this.ContactForm.setValue({
      'contacttype' : '',
      'username' : '',
      'useremail' : '',
      'usercomp' : '',
      'usermob' : '',
      'usercountry' : '',
      'userwebsite' : '',
      'usermsg' : '',
    });
  }

  submitForm(ContactForm){
    if (this.ContactForm.valid) {
      const body=(ContactForm.value);
      this.http.post('/apis/contact', body ,httpOptions).subscribe(
        (response)=>{
          // console.log(response);
          this.contact_form_response=true;
          this.reset();
          setTimeout(() => {
            this.contact_form_response=false;
          }, 5000);
        },
        (err) => {
          // console.log(err);
        }
      );
    }
  }

  setHeights(){
    var bannerbgcontact = document.getElementById('bannerbgcontact').clientHeight;
    document.getElementById("bannercontentcontact").style.height = bannerbgcontact+"px";

    if (window.innerWidth>600) {
      var formHeight = document.getElementById('contact-form-section').clientHeight;
      document.getElementById("contact-info-section").style.height = formHeight+"px";
    }
  }

  ngOnInit(): void {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }

}
