import { SmeComponent } from './product/sme/sme.component';
import { BankdetailsComponent } from './bankdetails/bankdetails.component';
import { JoinusComponent } from './joinus/joinus.component';
import { PricingComponent } from './pricing/pricing.component';
import { LogodesignComponent } from './product/logodesign/logodesign.component';
import { WhitelabelComponent } from './product/whitelabel/whitelabel.component';
import { ApiintegrationComponent } from "./product/apiintegration/apiintegration.component";
import { NotfoundComponent } from './notfound/notfound.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { BlogComponent } from "./blog/blog.component";


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path:'Aboutus', component:AboutusComponent},
  {path:'Contactus', component:ContactusComponent},
  {path:'product/Whitelabel', component:WhitelabelComponent},
  {path:'product/API', component:ApiintegrationComponent},
  {path:'product/SME', component:SmeComponent},
  {path:'Pricing', component:PricingComponent},
  // {path:'Joineway', component:JoinusComponent},
  // {path:'BankDetails', component:BankdetailsComponent},
  // {path:'Blog', component:BlogComponent},
  {path:'**', component:NotfoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
