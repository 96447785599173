<div class="header-top">
    <div class="container">
        <span class="">
            <a href="tel://01294017626" id="phone">7033883934</a>
        </span>
        <span class="pull-right">
            <a data-toggle="modal" data-target="#RequestDemo" class="btn " style="border-radius: 4px;border: 1px solid #000;padding: 0.2rem 1.25rem;margin-top: 5px;">Request a demo</a>
        </span>
    </div>
</div>
<div id="RequestDemo" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Request a Demo</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="alert alert-success" *ngIf="contact_response">
                We Received your request. Our Executive will reach you soon.
            </div>
            <form [formGroup]="RequestDemoForm" (ngSubmit)="submitForm(RequestDemoForm)" class="clearfix ts-form ts-form-email ts-inputs__transparent">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6 col-sm-6">
                                    <div class="field-wrapper">
                                        <input type="text" formControlName="username">
                                        <div class="field-placeholder"><span>Name *</span></div>
                                    </div>
                                    <div class="field-wrapper">
                                        <input type="email" formControlName="useremail">
                                        <div class="field-placeholder"><span>Email *</span></div>
                                    </div>
                                    <div class="field-wrapper">
                                        <input type="text" formControlName="usercomp">
                                        <div class="field-placeholder"><span>Company *</span></div>
                                    </div>
                                </div>
                                <!--end col-md-6 col-sm-6 -->
                                <div class="col-md-6 col-sm-6">
                                    <div class="field-wrapper">
                                        <input type="text" formControlName="usermob" pattern=".{10,}" title="10 characters minimum" maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/,'')">
                                        <div class="field-placeholder"><span>Mobile No. *</span></div>
                                    </div>
                                    <div class="field-wrapper">
                                        <input type="text" formControlName="userwebsite">
                                        <div class="field-placeholder"><span>Website</span></div>
                                    </div>
                                    <!--end form-group -->
                                    <div class="form-group">
                                        <label style="margin: 0;">When would you like to start *</label>
                                        <br>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"  formControlName="userabtstart" value="0-1 Week" required>0-1 Week
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"  formControlName="userabtstart" value="1-3 Weeks" required>1-3 Weeks
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input"  formControlName="userabtstart" value="4 Weeks or More" required>4 Weeks or More
                                            </label>
                                        </div>
                                    </div>
                                    <!--end form-group -->
                                </div>
                                <!--end col-md-6 col-sm-6 -->
                            </div>
                            <!--end row -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="field-wrapper">
                                        <textarea formControlName="usermsg" rows="5"></textarea>
                                        <div class="field-placeholder"><span>Message *</span></div>
                                    </div>
                                </div>
                                <!--end col-md-12 -->
                            </div>
                            <!--end form-group -->
                            <div class="form-reqest-demo-status"></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary pull-right" [disabled]="RequestDemoForm.invalid">Submit</button>
                </div>
            </form>
        </div>

    </div>
</div>
<header id="ts-hero">
    <!--NAVIGATION ******************************************************************************************-->
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top ts-separate-bg-element" data-bg-color="#eee">
        <div class="container">
            <a class="navbar-brand" routerLink="/">
                <img src="assets\img\logo\eway-logo.png" alt="EWAYORBIT" class="logo">
            </a>
            <!--end navbar-brand-->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-iconx fa fa-bars"></span>
            </button>
            <!--end navbar-toggler-->
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <ul class="navbar-nav ml-auto">
                    <!-- <a class="nav-item nav-link ts-scroll" routerLink="Aboutus">About</a> -->
                    <!-- <a class="nav-item nav-link ts-scroll" routerLink="Blog">Blog</a> -->
                    <li><a class="nav-item nav-link active ts-scroll" routerLink="">Home <span class="sr-only">(current)</span></a></li>
                    <li>
                        <span class="nav-item nav-link ts-scroll visible-sm" data-toggle="collapse" data-target="#navbarsubproduct" aria-controls="navbarsubproduct" aria-expanded="false" aria-label="Toggle navigation">Products
                            <button class="" type="button"  style="border: 0;">
                                <span class="fa fa-angle-down"></span>
                        </button>
                        </span>
                        <span class="nav-item nav-link ts-scroll visible-lg">Products
                        </span>
                        <div class="drop-menu collapse navbar-collapse" id="navbarsubproduct">
                            <ul class="sub-menu">
                                <li><a class="nav-item nav-link ts-scroll" routerLink="product/Whitelabel"><i class="fa fa-angle-right"></i> Whitelabel</a></li>
                                <li><a class="nav-item nav-link ts-scroll" routerLink="product/API"><i class="fa fa-angle-right"></i> API Integration</a></li>
                                <li><a class="nav-item nav-link ts-scroll" routerLink="product/SME"><i class="fa fa-angle-right"></i> SME</a></li>
                            </ul>
                        </div>
                    </li>

                    <li><a class="nav-item nav-link ts-scroll" routerLink="Pricing">Pricing</a></li>
                    <li><a class="nav-item nav-link ts-scroll" routerLink="Contactus">Contact</a></li>
                </ul>
                <!--end navbar-nav-->
            </div>
            <!--end collapse-->
        </div>
        <!--end container-->
    </nav>
    <!--end navbar-->
</header>