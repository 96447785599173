import { Component, OnInit } from '@angular/core';

declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-whitelabel',
  templateUrl: './whitelabel.component.html',
  styleUrls: ['./whitelabel.component.css']
})
export class WhitelabelComponent implements OnInit {

  constructor() { }

  setHeights(){
    var bannerbgwhitelabel = document.getElementById('bannerbgwhitelabel').clientHeight;
    document.getElementById("bannerapiwhitelabel").style.height = bannerbgwhitelabel+"px";
  }

  ngOnInit(): void {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }

}
