import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactusComponent } from './contactus/contactus.component';
import { WhitelabelComponent } from './product/whitelabel/whitelabel.component';
import { LogodesignComponent } from './product/logodesign/logodesign.component';
import { BankdetailsComponent } from './bankdetails/bankdetails.component';
import { HomeComponent } from './home/home.component';
import { PricingComponent } from './pricing/pricing.component';
import { JoinusComponent } from './joinus/joinus.component';
import { ServicesComponent } from './services/services/services.component';
import { CallbackComponent } from './callback/callback.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { BlogComponent } from './blog/blog.component';
import { ApiintegrationComponent } from './product/apiintegration/apiintegration.component';
import { EnquiryformComponent } from './enquiryform/enquiryform.component';
import { SmeComponent } from './product/sme/sme.component';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutusComponent,
    ContactusComponent,
    WhitelabelComponent,
    LogodesignComponent,
    BankdetailsComponent,
    HomeComponent,
    PricingComponent,
    JoinusComponent,
    ServicesComponent,
    CallbackComponent,
    NotfoundComponent,
    BlogComponent,
    ApiintegrationComponent,
    EnquiryformComponent,
    SmeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
