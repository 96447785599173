<section class="ts-block xpb-6 xpt-6" id="enq_form" data-bg-color="#f6f6f6">
    <div class="container" id="enquirycontainer">
        <div class="col-md-10s offset-md-1s ts-box xmb-0 p-5 ts-mt__n-10">
            <div class="row">
                <div class="col-md-12">
                    <h3>Enquire Now</h3>
                    <div class="alert alert-success" *ngIf="enquiry_response">
                        We Received your request. Our Executive will reach you soon.
                    </div>
                    <form [formGroup]="EnquiryForm" (ngSubmit)="submitForm(EnquiryForm)" class="clearfix ts-form ts-form-email ts-inputs__transparent">
                        <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <div class="field-wrapper">
                                    <input type="text" formControlName="username" required>
                                    <div class="field-placeholder"><span>Name *</span></div>
                                </div>
                                <div class="field-wrapper">
                                    <input type="email" formControlName="useremail" required>
                                    <div class="field-placeholder"><span>Email *</span></div>
                                </div>
                                <div class="field-wrapper">
                                    <input type="text" formControlName="usercomp" required>
                                    <div class="field-placeholder"><span>Company *</span></div>
                                </div>
                            </div>
                            <!--end col-md-6 col-sm-6 -->
                            <div class="col-md-6 col-sm-6">
                                <div class="field-wrapper">
                                    <input type="text" formControlName="usermob" required pattern=".{10,}" title="10 characters minimum" maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/,'')">
                                    <div class="field-placeholder"><span>Mobile No. *</span></div>
                                </div>
                                <div class="field-wrapper">
                                    <input type="text" formControlName="userwano" pattern=".{10,}" title="10 characters minimum" maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/,'')">
                                    <div class="field-placeholder"><span>Whatsapp No.</span></div>
                                </div>
                                <!--end form-group -->
                                <div class="form-group">
                                    <label style="margin: 0;">When would you like to start *</label>
                                    <br>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" formControlName="userabtstart" value="0-1 Week" required>0-1 Week
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" formControlName="userabtstart" value="1-3 Weeks" required>1-3 Weeks
                                        </label>
                                    </div>
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" formControlName="userabtstart" value="4 Weeks or More" required>4 Weeks or More
                                        </label>
                                    </div>
                                </div>
                                <!--end form-group -->
                            </div>
                            <!--end col-md-6 col-sm-6 -->
                        </div>
                        <!--end row -->
                        <div class="row">
                            <div class="col-md-12">
                                <div class="field-wrapper">
                                    <textarea formControlName="usermsg" required rows="5"></textarea>
                                    <div class="field-placeholder"><span>Message *</span></div>
                                </div>
                            </div>
                            <!--end col-md-12 -->
                        </div>
                        <!--end row -->
                        <div class="form-group clearfix">
                            <button type="submit" class="btn btn-primary float-right" [disabled]="EnquiryForm.invalid">Submit</button>
                        </div>
                        <!--end form-group -->
                        <div class="form-contact-status"></div>
                    </form>
                    <!--end form-contact -->
                </div>
            </div>
            <!--end row-->
        </div>
        <!--end ts-box-->
    </div>
    <!--end container-->
</section>