<main id="ts-content">

    <!--HERO CONTENT ****************************************************************************************-->
    <div class="container align-self-center">
        <div class="row align-items-center" id="bannercontenthome">
            <div class="col-sm-7">
                <!-- <h3 class="ts-opacity__50">Get Organized!</h3> -->
                <h1><i class="fa fa-thumbs-o-up"></i> The Best
                    <br> Travel Technology
                    <br> Platform
                </h1>

            </div>
            <!--end col-sm-7 col-md-7-->
            <div class="col-sm-5 sd-none d-sm-block">
                <div class="owl-carousesl text-center" data-owl-nav="1" data-owl-loop="1">
                    <img src="assets\img\img-home-screen.png" class="d-inline-block mw-100 p-md-5 p-lg-0 ts-width__auto" alt="">
                </div>
            </div>
            <!--end col-sm-5 col-md-5 col-xl-5-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="ts-background" id="bannerbghome" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3">
        <div class="ts-svg ts-z-index__2">
            <img src="assets\svg\wave-static-01.svg" class="w-100 position-absolute ts-bottom__0" style="bottom: -1px;">
        </div>
        <div class="ts-background-image ts-parallax-element" data-bg-color="#4345d2" data-bg-image="assets/img/bg-gardient-layer.jpg" data-bg-blend-mode="hard-light"></div>
    </div>
</main>
<section class="ts-block home-ewo-desc" data-bg-color="#ededed">
    <div class="container">

        <div class="row">
            <div class="col-md-12 col-xl-12">
                <p>
                    Ewayorbit is a Travel portal development company providing Online Travel portal Solutions & Booking Engines to Travel agents. If you are developing your travel business, we are here to help you find all the travel portal development related solutions.
                    Our team of experienced professionals provides the best travel website development services.
                </p>
            </div>
        </div>
    </div>
</section>
<!--WHAT IS APPSTORM ************************************************************************************-->
<section id="what-is-appstorm" class="ts-block">
    <div class="container">
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-5 col-xl-5 text-center" data-animate="ts-fadeInUp" data-delay="0.1s" data-offset="100">
                <div class="px-3">
                    <img src="assets\img\left-image.png" class="mw-100 ts-border-radius__md" alt="">
                </div>
            </div>
            <div class="col-md-1 col-xl-1">&nbsp;</div>
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100" style="align-self: center;">
                <div class="">
                    <h3>Built to meet your travel business needs</h3>
                </div>
                <p class="justify">
                    We provide customized travel website development to your changing requirements. Ewayorbit is specialized in portal design development and integration of various GDSS XML APIs, testing & deployment to client domain after which we also maintain the technical
                    and customer support services Ewayorbit help those who are looking for online travel portal development services and B2B & B2C Online Travel Portals.
                </p>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--END WHAT IS APPSTORM ********************************************************************************-->

<!--HOW IT LOOKS ****************************************************************************************-->
<section id="how-it-looks" class="pb-0 ts-block text-center ts-overflow__hidden ts-shape-mask__downs" data-bg-color="#ededed" data-bg-image="assets/img/bg-shapes-image.png" data-bg-repeat="no-repeat" data-bg-position="bottom" data-bg-size="inherit">
    <div class="container">
        <div class="ts-title">
            <h2>We deliver to clients that evolve</h2>
            <!-- <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h5> -->
        </div>
        <!--end ts-title-->
        <div>
            <img src="assets\img\img-desktop.png" class="mw-100" alt="">
        </div>
    </div>
</section>

<section class="ts-block">
    <div class="container">
        <!--end ts-title-->
        <div class="row align-items-center">
            <div class="col-md-5 col-xl-5 text-center" data-animate="ts-fadeInUp" data-delay="0.1s" data-offset="100">
                <div class="px-3">
                    <br><br><br><br>
                    <img src="assets\img\sme-d1.jpg" class="mw-100 ts-border-radius__md" alt="">
                </div>
            </div>
            <div class="col-md-1 col-xl-1">&nbsp;</div>
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100" style="align-self: center;">
                <div class="">
                    <h3>Grow your business online with our starter bundle in just 4 easy steps</h3>
                </div>
                <p class="justify">
                    We offer a wide variety of designer templates to make your website both professional and functional.
                </p>
                <div class="row">
                    <div class="col-md-6">
                        <ul class="arrowlist">
                            <li>E-commerce websites</li>
                            <li>Business websites</li>
                            <li>Designer templates</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <a href="/product/SME" class="btn btn-light btn-lg pull-right">Start Now</a>
                    </div>
                </div>

            </div>

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section class="ts-block" data-bg-color="#ededed">
    <div class="container">
        <!--end ts-title-->
        <div class="row align-items-center">
            <div class="col-md-5 col-xl-5" data-animate="ts-fadeInUp" data-offset="100" style="align-self: center;">
                <div class="">
                    <h3>Your travel website. Fully loaded. Within a week.</h3>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ul class="arrowlist">
                            <li>Mobile responsive website</li>
                            <li>Booking engine</li>
                            <li>Online payments</li>
                        </ul>
                    </div>
                </div>
                <p class="justify">
                    Comprehensive system to manage your website, inventory, rates, and agents. Ready to go.
                </p>

            </div>
            <div class="col-md-7 col-xl-7 text-center" data-animate="ts-fadeInUp" data-delay="0.1s" data-offset="100">
                <div class="">
                    <img src="assets\img\home-banner-3.png" class="xmw-100 ts-border-radius__md" alt="" style="margin-left: 50px;width: 105%;">
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<section id="organize" class="ts-block mt-5">
    <div class="container">
        <div class="ts-title text-center">
            <h2>What We Offer</h2>
        </div>
        <!--end ts-title-->
        <div class="row align-items-center">
            <div class="col-md-4">
                <figure class="text-right ts-xs-text-center" data-animate="ts-fadeInUp">
                    <figure class="icon">
                        <img src="assets\img\travel.png" class="" alt="">
                    </figure>
                    <h4 class="mb-2">Travel Portal</h4>
                    <p>
                        Next generation travel technology platform with B2C & B2B booking interface.
                    </p>
                </figure>
                <figure class="text-right ts-xs-text-center" data-animate="ts-fadeInUp" data-delay=".1s">
                    <figure class="icon">
                        <img src="assets\img\api.png" class="" alt="">
                    </figure>
                    <h4 class="mb-2">API Integrations</h4>
                    <p>
                        We have a team of experienced developers for various api integrations.
                    </p>
                </figure>
                <figure class="text-right ts-xs-text-center" data-animate="ts-fadeInUp" data-delay=".3s">
                    <figure class="icon">
                        <img src="assets\img\erp.png" class="" alt="">
                    </figure>
                    <h4 class="mb-2">Enterprise Resource Planning</h4>
                    <p>
                        We develop scalable, enterprise-wide ERP systems to automate critical processes.
                    </p>
                </figure>
            </div>
            <!--end col-md-4-->

            <div class="col-md-4 my-5 d-flex justify-content-center align-items-center">
                <div class="image position-relative">
                    <img src="assets\img\img-phone-1st-screen.png" class="mw-100" alt="" data-animate="ts-zoomInShort" data-delay=".1s">
                    <aside class="ts-svg ts-svg__organic-shape-01 ts-background-size-contain" data-animate="ts-zoomInShort" data-delay=".4s"></aside>
                </div>
            </div>
            <!--end col-md-4-->

            <div class="col-md-4">
                <figure class="ts-xs-text-center" data-animate="ts-fadeInUp">
                    <figure class="icon">
                        <img src="assets\img\ecom.png" class="" alt="">
                    </figure>
                    <h4 class="mb-2">E-Commerce</h4>
                    <p>
                        We offer full-suite of ecommerce development services.
                    </p>
                </figure>
                <figure class="ts-xs-text-center" data-animate="ts-fadeInUp" data-delay=".1s">
                    <figure class="icon">
                        <img src="assets\img\app.png" class="" alt="" width="68px">
                    </figure>
                    <h4 class="mb-2">Travel App Development</h4>
                    <p>
                        We have special team of developers expertise in developing mobile apps for travel industry.
                    </p>
                </figure>
                <figure class="ts-xs-text-center" data-animate="ts-fadeInUp" data-delay=".3s">
                    <figure class="icon">
                        <img src="assets\img\web-app.png" class="" alt="">
                    </figure>
                    <h4 class="mb-2">Customized Web & App</h4>
                    <p>
                        We provide you full flexibilty on customization of your website and App.
                    </p>
                </figure>
            </div>
            <!--end col-md-4-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end #organize-->

<section class="ts-block" data-bg-color="#ededed">
    <div class="container">
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-5 col-xl-5 text-center" data-animate="ts-fadeInUp" data-delay="0.1s" data-offset="100">
                <div class="px-3">
                    <br><br><br>
                    <img src="assets\img\travel_hospitality.png" class="mw-100 ts-border-radius__md" alt="">
                </div>
            </div>
            <div class="col-md-1 col-xl-1">&nbsp;</div>
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100" style="align-self: center;">
                <div class="">
                    <h3>Travel & Hospitality</h3>
                </div>
                <p class="justify">
                    Our travel and hospitality software development services help you build robust and cost-effective solutions. Whether it is with B2B & B2C booking portals, tour operator software, hotel booking software, etc. we’re there to help you. We engage with travel
                    agents, tour operators, Online Travel Agencies (OTAs), Travel Management Company (TMCs), Destination Management Company (DMCs) and hospitality giants to elevate their business models & navigate market complexities. <br><br>Our
                    team leverages the latest tech like AI, ML, predictive analytics, blockchain, cloud and more to build intelligent solutions for better market reach & customer engagement.
                </p>
            </div>

        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>

<!--FEATURES ********************************************************************************************-->
<section id="features" class="ts-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xl-6" data-animate="ts-fadeInUp" data-offset="100">
                <br><br>
                <div class="ts-title">
                    <h2>The New Era of<br>Travel Technology</h2>
                </div>
                <!--end ts-title-->
                <p>
                    The world is changing. Our industry must change with it. We’re on a mission to power the future of travel. By focusing all our energy and expertise we are working on new technologies, which will produce a much faster and robust product.
                </p>

            </div>

            <div class="col-md-6 col-xl-6 text-center">
                <div class="position-relative">
                    <figure class="sp-5" data-animate="ts-zoomInShort" data-delay="0.1s">
                        <img src="assets\img\banner-10.png" class="mw-100 sts-shadow__lg" alt="">
                    </figure>
                </div>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--FEATURES ********************************************************************************************-->


<section id="our-clients" class="ts-block text-center">
    <div class="container">
        <div class="ts-title">
            <h2>Our Clients</h2>
        </div>
        <!--end ts-title-->
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="owl-carousel ts-carousel-blockquote" data-owl-dots="1" data-animate="ts-zoomInShort">
                    <blockquote class="blockquote">
                        <!--person image-->
                        <figure>
                            <aside>
                                <i class="fa fa-quote-right"></i>
                            </aside>
                            <div class="ts-circle__lg" data-bg-image="assets/img/client/c22145623.jpg"></div>
                        </figure>
                        <!--end person image-->
                        <!--cite-->
                        <p>
                            The team has impressed us the most with awesome after sales service. They have built a very powerful flexible booking engine, which help us promoting our products.
                        </p>
                        <!--end cite-->
                        <!--person name-->
                        <footer class="blockquote-footer">
                            <h4>Rajeev Bhatia</h4>
                        </footer>
                        <!--end person name-->
                    </blockquote>
                    <!--end blockquote-->
                    <blockquote class="blockquote">
                        <!--person image-->
                        <figure>
                            <aside>
                                <i class="fa fa-quote-right"></i>
                            </aside>
                            <div class="ts-circle__lg" data-bg-image="assets/img/client/c22515135.jpg"></div>
                        </figure>
                        <!--end person image-->
                        <!--cite-->
                        <p>
                            Got our website created by Ewayorbit after searching a lot of OTA development companies in India . Thank you team for your cooperation ,the website was customized as we expected .
                        </p>
                        <!--end cite-->
                        <!--person name-->
                        <footer class="blockquote-footer">
                            <h4>Ervin Bell</h4>
                        </footer>
                        <!--end person name-->
                    </blockquote>
                    <!--end blockquote-->
                </div>
            </div>
        </div>

    </div>
</section>
<!--end #our-clients.ts-block-->

<!--NUMBERS *********************************************************************************************-->
<section class="ts-block" style="padding: 13rem 0;">
    <div class="container align-self-center">
        <div class="row align-items-center text-white text-center">
            <div class="col-sm-12">
                <h2>Leverage our travel software development services to delight customers & accelerate growth</h2>
            </div>
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="ts-background home-footer-bg" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3">
        <div class="ts-background-image ts-parallax-element" data-bg-color="#0008" data-bg-image="assets/img/home-footer-bg.jpg" data-bg-blend-mode="overlay"></div>
    </div>
</section>
<!--END NUMBERS *****************************************************************************************-->