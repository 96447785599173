<main id="ts-content" style="margin-top: 100px;">

    <!--HERO CONTENT ****************************************************************************************-->
    <div class="container align-self-center">
        <div class="row align-items-center" id="bannercontentcontact">
            <div class="col-sm-7">
                <h1>Grow your business with ewayorbit</h1>

                <p style="color: white;font-size: large;">We believe that the success of our business is inherently interwined with the success of our clients</p>

            </div>
            <!--end col-sm-7 col-md-7-->
            <div class="col-sm-5 d-none d-sm-block">
                <div class="owl-carousesls text-center" data-owl-nav="1" data-owl-loop="1" style="">

                </div>
            </div>
            <!--end col-sm-5 col-md-5 col-xl-5-->
        </div>
        <!--end row-->
        <div class="ts-background" id="bannerbgcontact" data-bg-image-opacity=".6" data-bg-parallax="scroll" data-bg-parallax-speed="3" style="height: 75%;">
            <div class="ts-background-image ts-parallax-element" data-bg-color="#4345d2" data-bg-image="assets/img/bg-pattern.jpg" data-bg-blend-mode="overlay"></div>
        </div>
    </div>
    <!--end container-->

</main>

<section class="ts-block">
    <div class="container">
        <div class="row">
            <div class="col-md-8" id="contact-form-section">
                <h2 style="font-size: 1.9rem;">Get the help you need, every step of the way</h2>
                <div class="alert alert-success" *ngIf="contact_form_response">
                    We Received your request. Our Executive will reach you soon.
                </div>
                <form [formGroup]="ContactForm" (ngSubmit)="submitForm(ContactForm)" class="clearfix ts-form ts-form-email ts-inputs__transparent" data-php-path="http://ang.ewayorbit.com/enquiry.php">
                    <div class="row" style="margin-top: 30px;">
                        <div class="col-md-12 col-sm-12">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-check-inline">
                                            <label class="form-check-label" style="font-size: medium;">
                                                <input type="radio" class="form-check-input" formControlName="contacttype" value="Sales Representative" required> Sales Representative
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-check-inline">
                                            <label class="form-check-label" style="font-size: medium;">
                                                <input type="radio" class="form-check-input" formControlName="contacttype" value="Technical Expert" required>Technical Expert
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-6">
                            <div class="field-wrapper">
                                <input type="text" formControlName="username">
                                <div class="field-placeholder"><span>Name*</span></div>
                            </div>

                            <div class="field-wrapper">
                                <input type="text" formControlName="usercomp">
                                <div class="field-placeholder"><span>Company Name*</span></div>
                            </div>

                            <div class="field-wrapper">
                                <input type="text" formControlName="usercountry">
                                <div class="field-placeholder"><span>Country*</span></div>
                            </div>

                        </div>
                        <!--end col-md-6 col-sm-6 -->
                        <div class="col-md-6 col-sm-6">
                            <div class="field-wrapper">
                                <input type="email" formControlName="useremail">
                                <div class="field-placeholder"><span>Email*</span></div>
                            </div>

                            <div class="field-wrapper">
                                <input type="text" formControlName="usermob" pattern=".{10,}" title="10 characters minimum" maxlength="10" oninput="this.value=this.value.replace(/[^0-9]/,'')">
                                <div class="field-placeholder"><span>Mobile No.*</span></div>
                            </div>

                            <div class="field-wrapper">
                                <input type="text" formControlName="userwebsite">
                                <div class="field-placeholder"><span>Website</span></div>
                            </div>
                        </div>
                        <!--end col-md-6 col-sm-6 -->
                    </div>
                    <!--end row -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="field-wrapper">
                                <textarea formControlName="usermsg" rows="5"></textarea>
                                <div class="field-placeholder"><span>Message*</span></div>
                            </div>
                            <!--end form-group -->
                        </div>
                        <!--end col-md-12 -->
                    </div>
                    <!--end row -->
                    <div class="form-group clearfix">
                        <button class="btn btn-primary float-right" [disabled]="ContactForm.invalid">Submit</button>
                    </div>
                    <!--end form-group -->
                    <div class="form-contact-status"></div>
                </form>
            </div>
            <div class="col-md-4" id="contact-info-section">
                <h4 style="margin: 0;">Drop us mail</h4>
                <i class="fa fa-envelope"></i> &nbsp;<a href="mailto:b2b@ewayorbit.com" style="color: #191919;">b2b@ewayorbit.com</a>
                <br><br>
                <h4 style="margin: 0;">Visit Us</h4>
                <i class="fa fa-globe"></i> &nbsp;<a href="http://ewayorbit.com" style="color: #191919;">Ewayorbit</a>, 4th floor, Sam tower, Near petrol pump, Bank more, Dhanbad, Jharkhand - 826001
                <br><br>
                <h4 style="margin: 0;">Sales</h4>
                <i class="fa fa-phone"></i> +91 88604 25311
                <br><br>
                <h4 style="margin: 0;">Support</h4>
                <i class="fa fa-phone"></i> 7033883934
                <br><br>
            </div>
        </div>
    </div>
</section>

<iframe width="100%" height="450" frameborder="0" style="border: 0;margin-bottom: -7px;" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBgqcHfQ0SmhvjUQZJJZYOecTfzXpiAA88&q=23.7847506,86.4163732" allowfullscreen></iframe>