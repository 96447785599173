import { Component, OnInit } from '@angular/core';

declare function callonloadmethod();
declare function returntotop();

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {

  constructor() { }

  setHeights(){
    var bannerbgabout = document.getElementById('bannerbgabout').clientHeight;
    document.getElementById("bannercontentabout").style.height = bannerbgabout+"px";
  }

  ngOnInit(): void {
    callonloadmethod();
    returntotop();
    this.setHeights();
  }

}
